
/* Imports */
import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	Output, EventEmitter
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';

/* Services */
import {
	AlertService,
	AuthenticationService,
	DownloadService,
	FavoriteService,
	ProjectService,
	PermissionService,
	UploadService,
	byId
} from '@shared/services';
import { analyticsLayer } from '@shared/analyticsLayer';
import { availableFeatureFlags, flagLayer } from "@shared/featureFlags";

/* Models */
import { Alert, Project } from '@shared/models';
import _, {isEmpty, isNil, merge, unionBy} from 'lodash';
import moment from 'moment';
import plural from "pluralize";

export const toAPIFormat = ( date ) => moment( date ).format( "YYYY-MM-DDTHH:mm:ss.SSSZ" );

@Component({
	selector: 'app-data-projects',
	templateUrl: './data.projects.component.html',
	styleUrls: ['./data.projects.component.scss']
})
export class DataProjectsComponent implements OnInit {

	/* ViewChild */
	@ViewChild("itemMenuTrigger") menuTrigger: MatMenuTrigger;

	public defaultMenuTriggers: Array<any> = [];

	@ViewChild('fileInput') uploadInput: ElementRef;
	@ViewChild(MatSort) set sort(s: MatSort) {
		if (s) { this.dataSource.sort = s; }
	};
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	/* Input */
	@Input('project') set project(proj: Project) {
		if (proj) { this.setup(proj); }
	}
	@Output() projectChange: EventEmitter<any> = new EventEmitter<any>();
	public _project: Project;
	get project(): Project { return this._project; }

	public dataSource: MatTableDataSource<any> = new MatTableDataSource();
	public selection = new SelectionModel<any>(true, []);
	public displayedColumns: string[] = ['name', 'size', 'created_at', 'options'];
	public user: any;
	public activeItem: any;

	public isProcessing: boolean = true;
	public isDownloading: boolean = false;
	public allSelected: boolean = false;

	public userPermissions: any;
	public contextMenuPosition = { x: '0px', y: '0px' };
	public projectListSubscription: any;


	public failedUploadItems = [];
	public uploadingItems = [];
	public completedItems = [];
	public allUploadItems = [];
	public percentageComplete = 0;

	constructor(
		private _cdr: ChangeDetectorRef,
		private _alertService: AlertService,
		private _authService: AuthenticationService,
		private _downloadService: DownloadService,
		private _favoriteService: FavoriteService,
		private _projectService: ProjectService,
		private _uploadService: UploadService,
		private _permissionService: PermissionService,
	) {

	}	// End-of constructor

	ngOnInit() {

		this.user = this._authService.user;

		this.projectListSubscription?.unsubscribe();
		this.projectListSubscription = this._uploadService.projectListChange.subscribe(({renderState}) => {
			this.trackAndUpdateUploads(renderState);
		});

	}	// End-of ngOnInit

	trackAndUpdateUploads(renderState) {

		const project = this.project ? renderState.projectList?.find(byId(this.project.id)) : null;

		if (project?.files?.length) {
			const dataFiles = project.files.filter(x => x.uploadType === "File");
			if (dataFiles?.length) {
				dataFiles.forEach(file => {
					if (!file.created_at) {
						file.created_at = toAPIFormat(moment().utc()); // UTC time to match API
					}
				});
				this.updateUpload(dataFiles);
			}
		}
	}

	clearAndUpdate() {
		this.uploadingItems = [];
	}

	updateUpload(uploadFiles) {

		this.failedUploadItems = uploadFiles?.filter(x => x.status === "FAILURE") ?? [];
		this.uploadingItems = uploadFiles?.filter(x => x.status === "ACTIVE") ?? [];
		this.completedItems = uploadFiles?.filter(x => x.status === "SUCCESS") ?? [];
		this.allUploadItems = uploadFiles;
		this.percentageComplete = this.getPercentageComplete(uploadFiles);

		if (this.uploadingItems?.length <= 0) {
			this.getFiles(true);
		}

	}

	getPercentageComplete(uploadFiles): number {
		if (!uploadFiles?.length) return 0;
		const totalPercent = uploadFiles
			.reduce((acc, image) => {
				acc += image.percentageComplete;
				return acc;
			}, 0);
		return Math.round(totalPercent / uploadFiles.length) ?? 0;
	}

	async setup(proj: Project) {

		this._project = proj;

		await this._permissionService.getUserPermissions(proj.organization_id).then(rtnPermissions => {
			this.userPermissions = rtnPermissions;
		}).catch(console.error);

		this.getFiles();

	}	// End-of setup

	async getFiles(forceUpdate = false) {

		let projectFiles: Array<any> = this._project?.project_files;

		if (!projectFiles?.length || forceUpdate) {
			projectFiles = (await this._projectService.getProjectFilesV2(this._project))
				?.filter((x) => !x.export && !x.trash);
			this._project.project_files = projectFiles;
		}

		this.dataSource.data = projectFiles;
		this.dataSource.paginator = this.paginator;
		this.isProcessing = false;
		this._cdr.detectChanges();

	}	// End-of updateFileList

	getFavorites(files: Array<any> = []) {
		this._favoriteService.getList().then(favList => {
			if (favList.project_files && Array.isArray(favList.project_files)) {
				favList.project_files.forEach(fav => {
					const index = files.findIndex(item => item.id === fav.project_file_id);
					if (index > -1) {
						files[index].favorited = true;
						files[index].favorited_id = fav.id;
					}
				});
			}
		});
	}

	selectOne(row) {

		this.selection.toggle(row);
		if (this.allSelected) {
			this.allSelected = false;
		} else if (this.selection.selected.length === this.dataSource.data.length) {
			this.allSelected = true;
		}

	}	// End-of selectOne

	selectAll(): void {

		if (this.allSelected) {
			this.selection = new SelectionModel<Element>(true, []);
			this.allSelected = false;
		} else {
			this.selection = new SelectionModel<Element>(true, this.dataSource.data);
			this.allSelected = true;
		}

	}	// End-of selectAll

	download(file): void {
		this._downloadService.setStatus("Downloading file, please do not refresh this page.");
		this.isDownloading = true;
		this._projectService.downloadFile(file).then(rtnFile => {
			this._downloadService.downloadHelper(rtnFile, file.name);
			this.isDownloading = false;
			this._downloadService.clearStatus();
		});
		analyticsLayer.trackDownload(file, "ProjectFile");
	}


	favorite(item): void {

		if (item.favorited) {
			this._favoriteService.remove(item.favorited_id).then(() => {
				item.favorited = false;
				this._alertService.notification(new Alert('Removed from Favorites'));
				analyticsLayer.trackFavorite("remove", item, "ProjectFile");
			});
		} else {
			this._favoriteService.create({ project_file_id: item.id }).then(rtnData => {
				item.favorited_id = rtnData.favorite_id;
				item.favorited = true;
				this._alertService.notification(new Alert('Added to Favorites', 'star_border'));
				analyticsLayer.trackFavorite("add", item, "ProjectFile");
			});
		}

	}	// End-of favorites


	openMenu(e, i, row): void {
		if (this.menuTrigger) {
			e.preventDefault();
			this.contextMenuPosition.x = e.clientX + 'px';
			this.contextMenuPosition.y = e.clientY + 'px';
			this.activeItem = row;
			this.menuTrigger._handleClick(e);
			this._cdr.detectChanges();
		}
	}

	async deleteItem(item) {
		this._projectService.trashFile(item)
			.then(() => {
				this.dataSource.data = this.dataSource.data?.filter(entry => entry.id !== item.id) ?? [];
				this.project = Object.assign(this.project, { project_files: this.dataSource.data });
				this._alertService.trashed(item.name);
				const emitProject = Object.assign({}, this._project, { project_files: this.dataSource.data });
				this.projectChange.emit({ project: emitProject, type: "project_files" });
				analyticsLayer.trackRemove(item, "ProjectFile");
			})
			.catch( e => {
				console.error(e)
				this._alertService.notify(`Failed to move ${item.name} to the trash`, 'error');
			} )
	}

	onMenuClose() {
		this.activeItem = null;
	}

	isActiveItem(item): boolean {
		return !isNil(item?.id) && item.id === this.activeItem?.id;
	}

	protected readonly plural = plural;
}	// End-of DataProjectComponent
