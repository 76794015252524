
<div class="project-table">

	<div class="loading-container" *ngIf="isProcessing && !dataSource?.data?.length">
		<div class="dot-stretching"></div>
	</div>

	<ng-container *ngIf="uploadingItems?.length">
		<div class="uploading-items">
			<span>
				<p>
					Uploading {{ allUploadItems.length }} {{plural("Files", allUploadItems.length)}} ({{ percentageComplete }}% complete)
				</p>
				<mat-icon *ngIf="failedUploadItems?.length" matTooltip="One or more images had an issue, more information will be available after the upload.">warning</mat-icon>
			</span>
		</div>
	</ng-container>

	<mat-table *ngIf="!isProcessing && dataSource?.data?.length" [dataSource]="dataSource" matSort matSortActive="created_at" matSortDirection="desc" matSortDisableClear class="table-content">

		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<mat-header-cell *matHeaderCellDef style="max-width: 40px;">
				<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectAll() : null" [checked]="allSelected">
				</mat-checkbox>
			</mat-header-cell>
			<mat-cell *matCellDef="let row" style="max-width: 40px;">
				<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectOne(row) : null"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</mat-cell>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header disableClear> File name </mat-header-cell>
			<mat-cell
        		data-testid="data-name-cell"
				*matCellDef="let row">
				<span class="title-text ellipsis">{{row.name}}</span>
		  		<mat-icon matTooltip="Favorited" *ngIf="row.favorited"
					style="margin: auto 8px; cursor: pointer; color: #6a7483;">star_border</mat-icon>
      </mat-cell>
		</ng-container>

		<!-- Size Column -->
		<ng-container matColumnDef="size">
			<mat-header-cell class="phone-hidden" style="max-width: 124px;" *matHeaderCellDef mat-sort-header disableClear> Size </mat-header-cell>
			<mat-cell class="phone-hidden" style="max-width: 124px;" *matCellDef="let row">
				{{row.size ? (row.size | bytes) : '---'}}
			</mat-cell>
		</ng-container>

		<!-- Date Column -->
		<ng-container matColumnDef="created_at">
			<mat-header-cell class="phone-hidden" style="max-width: 248px;" *matHeaderCellDef mat-sort-header disableClear> Date uploaded </mat-header-cell>
			<mat-cell class="phone-hidden" style="max-width: 248px;"
				data-testid="data-created_at-cell"
				*matCellDef="let row">
				{{row.created_at ? (row.created_at | date) : '---'}}
			</mat-cell>
		</ng-container>

		<!-- Options Column -->
		<ng-container matColumnDef="options">
			<mat-header-cell *matHeaderCellDef style="max-width: 80px;"> </mat-header-cell>
			<mat-cell *matCellDef="let row; let i = index"
				style="max-width: 80px; cursor: pointer;">

				<button mat-icon-button tooltip="More" (click)="openMenu($event, i, row); $event.stopPropagation();">
					<mat-icon class="list-item-icon">more_horiz</mat-icon>
				</button>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row [ngClass]="{'active-item' : (isActiveItem(row))}" *matRowDef="let row; let i = index; columns: displayedColumns;" (contextmenu)="openMenu($event, i, row)"></mat-row>
	</mat-table>

	<button
		class="context-button"
		[style.left]="contextMenuPosition.x"
		[style.top]="contextMenuPosition.y"
		#itemMenuTrigger="matMenuTrigger"
		[matMenuTriggerFor]="itemMenu">
	</button>

	<mat-menu #itemMenu="matMenu" (closed)="onMenuClose()" class="little-menu centered-menu-text" data-testid="options-menu">
		<ng-template matMenuContent>
			<mat-list *ngIf="activeItem?.id" style="width: 100%; padding: 2px 0;">
				<mat-list-item [disabled]="!userPermissions['process']" (click)="deleteItem(activeItem);">
					<mat-icon> delete_outline </mat-icon>
					<span style="margin-left: 16px;"> Delete </span>
				</mat-list-item>
				<mat-list-item (click)="download(activeItem);">
					<mat-icon> vertical_align_bottom </mat-icon>
					<span style="margin-left: 16px;"> Download </span>
				</mat-list-item>
				<mat-list-item newSection="true" *ngIf="!activeItem.favorited" (click)="favorite(activeItem);">
					<mat-icon> star_border </mat-icon>
					<span style="margin-left: 16px;"> Add to Favorites </span>
				</mat-list-item>
				<mat-list-item newSection="true" *ngIf="activeItem.favorited" (click)="favorite(activeItem);">
					<mat-icon> grade </mat-icon>
					<span style="margin-left: 16px;"> Remove from Favorites </span>
				</mat-list-item>
			</mat-list>
		</ng-template>
	</mat-menu>
	<!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->

	<div *ngIf="!isProcessing && !dataSource?.data?.length" class="project-info">
		<mat-icon class="large-icon">assignment</mat-icon>

		<div>You have not uploaded any files. Click on ‘Upload’ to upload files.</div>
		<div>You can upload ground control points or any</div>
		<div>related files related to your photogrammetry project.</div>
	</div>
</div>
